<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ $t("manage_lecturer") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-dialog v-model="dialog" max-width="700px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #d6d0fc"
                      >
                        <span style="font-size: 1em; color: #3316f2">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_lecturer")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title v-if="this.lectId != ''">{{
                        $t("edit_lecturer")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_lecturer")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                          resetValidation();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 570px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                              <v-col sm="3" cols="12" class="py-0">
                                <label class="label required">{{
                                  $t("name_title")
                                }}</label>
                                <v-select
                                  v-model="title"
                                  placeholder="e.g Mr"
                                  :items="nameTitleList"
                                  label="All"
                                  single-line
                                  :rules="[rules.required]"
                                  outlined
                                ></v-select>
                              </v-col>
                              <v-col sm="9" cols="12" class="py-0">
                                <label class="label required">{{
                                  $t("name_en")
                                }}</label>
                                <v-text-field
                                  v-model="name_en"
                                  placeholder="e.g John Michael"
                                  :rules="[rules.required]"
                                  precision="3"
                                  outlined
                                ></v-text-field>
                              </v-col>

                              <v-col sm="12" cols="12" class="py-0">
                                <label class="label required">{{
                                  $t("name_kh")
                                }}</label>
                                <v-text-field
                                  v-model="name_kh"
                                  placeholder="e.g ចន មៃខិល"
                                  :rules="[rules.required]"
                                  precision="3"
                                  outlined
                                ></v-text-field>

                                <label class="label required">{{
                                  $t("gender")
                                }}</label>
                                <v-select
                                  v-model="gender"
                                  single-line
                                  placeholder="e.g Male"
                                  :items="genderList"
                                  label="All"
                                  outlined
                                  :rules="[rules.required]"
                                ></v-select>

                                <label class="label required">{{
                                  $t("work_contract")
                                }}</label>

                                <v-radio-group v-model="contract" row>
                                  <v-radio
                                    v-for="n in contract_type_list"
                                    :key="n"
                                    :label="`${n}`"
                                    color="indigo"
                                    :value="n"
                                  ></v-radio>
                                </v-radio-group>

                                <label class="label required">{{
                                  $t("email")
                                }}</label>
                                <v-text-field
                                  v-model="email"
                                  outlined
                                  placeholder="name@gmail.com"
                                  :rules="[rules.email]"
                                  :readonly="this.lectId != ''"
                                ></v-text-field>

                                <label class="label">{{
                                  $t("phone_numbers")
                                }}</label>

                                <v-text-field
                                  v-model="phone_numbers0"
                                  placeholder="e.g 012415263"
                                  type="number"
                                  outlined
                                ></v-text-field>

                                <v-text-field
                                  v-model="phone_numbers1"
                                  placeholder="e.g 058415263"
                                  type="number"
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          :disabled="!valid"
                          @click.prevent="onSaveClose(), validate()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialog1" max-width="400px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        style="height: 40px"
                        class="
                          text-capitalize
                          green
                          rounded-pill
                          text-white
                          float-right
                          mr-5
                        "
                        type="primary"
                      >
                        <v-icon class="mr-2">mdi-upload</v-icon>
                        {{ $t("import_file") }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>{{ $t("import_lecturer") }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="(dialog1 = false), clear(), resetValidation()"
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: 200px;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row>
                              <v-col sm="12" cols="12" class>
                                <label class="label required">{{
                                  $t("work_contract")
                                }}</label>

                                <v-radio-group v-model="contract" row>
                                  <v-radio
                                    class=""
                                    v-for="n in contract_type_list"
                                    :key="n"
                                    :label="`${n}`"
                                    color="indigo"
                                    :value="n"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col
                                sm="12"
                                cols="12"
                                style="margin-top: -30px"
                              >
                                <v-file-input
                                  show-size
                                  :rules="rulesFile"
                                  v-model="filecsv"
                                  accept=".csv"
                                  color="deep-purple accent-4"
                                  placeholder="Click here to select your file"
                                  label="Choose a file"
                                  type="file"
                                  prepend-icon="mdi-paperclip"
                                  :clearable="false"
                                  @change="handleFileUpload"
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog1 = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          class="green text-white"
                          :disabled="!valid"
                          @click.prevent="importLectCSV(), validate()"
                          >{{ $t("import") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-menu bottom offset-y transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-4 float-right white--text rounded-pill"
                        color="blue"
                        target="_blank"
                        v-on="on"
                        ><v-icon class="mr-2" size="18">
                          fas fa-file-download
                        </v-icon>

                        {{ $t("download_template") }}
                        <v-icon class="mr-2" size="18" right>
                          fas fa-chevron-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        link
                        style="min-height: 35px"
                        :href="this.internal_lecturer_csv"
                      >
                        <v-icon left class="mr-5"> far fa-file-alt </v-icon>
                        <v-list-item-title>
                          {{ $t("internal_lecturer") }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        link
                        style="min-height: 35px"
                        :href="this.external_lecturer_csv"
                      >
                        <v-icon left class="mr-5"> far fa-file-alt </v-icon>
                        <v-list-item-title>{{
                          $t("external_lecturer")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listLect"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th class="text-left">{{ $t("name_en") }}</th>
                              <th class="text-left">{{ $t("name_kh") }}</th>
                              <th class="text-left">{{ $t("email") }}</th>
                              <th class="text-center">{{ $t("contract") }}</th>
                              <th class="text-center">{{ $t("gender") }}</th>
                              <th class="text-center">
                                {{ $t("phone_numbers") }}
                              </th>
                              <th class="text-center">{{ $t("Action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody
                            style="white-space: nowrap"
                            v-if="items.length > 0"
                          >
                            <tr
                              v-for="(lect, index) in items"
                              v-bind:key="index"
                            >
                              <td class="text-center">{{ 1 + index++ }}</td>
                              <td class="text-left">
                                {{ lect.title }} {{ lect.name_en }}
                              </td>
                              <td class="text-left">{{ lect.name_kh }}</td>
                              <td class="text-left">{{ lect.email }}</td>
                              <td class="text-center">
                                <v-chip
                                  :color="getContractColor(lect.contract)"
                                  dark
                                >
                                  <v-icon left dark> mdi-clock </v-icon>
                                  {{ lect.contract }}
                                </v-chip>
                              </td>
                              <td class="text-center">
                                <v-chip
                                  :color="getGenderColor(lect.gender)"
                                  dark
                                >
                                  <v-icon left>
                                    mdi-account-circle-outline
                                  </v-icon>
                                  {{ lect.gender }}
                                </v-chip>
                              </td>

                              <td class="text-center">
                                <span
                                  v-for="(phone, index) in lect.phone_numbers"
                                  :key="index"
                                >
                                  <v-chip
                                    class="ma-2"
                                    color="success"
                                    outlined
                                    v-if="phone"
                                  >
                                    <v-icon left> mdi-phone </v-icon>
                                    {{ phone }}
                                  </v-chip>
                                </span>
                              </td>

                              <td class="text-center">
                                <v-btn
                                  @click="onEditItem(lect)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="primary"
                                >
                                  <v-icon size="15" dark>fa fa-pen</v-icon>
                                </v-btn>
                                <v-btn
                                  @click="deletelect(lect)"
                                  class="mx-2"
                                  fab
                                  color="error"
                                  small
                                >
                                  <v-icon size="15" dark>fa fa-trash</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                :colspan="headers.length + 2"
                                style="text-align: center"
                              >
                                <v-alert dense outlined type="error">
                                  No data available in table
                                </v-alert>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Papa from "papaparse";
import {
  getLecturer,
  createLecturer,
  updateLecturer,
  deleteLecturer,
  importLecturer,
} from "@schoolbase/web-client-lib";
import store from "@/store";
import {
  GENDER,
  TITLE,
  CONTRACT_TYPE,
  INTERNAL_LECTURE_TEMPLATE_CSV_FROM_FIREBASE,
  EXTERNAL_LECTURE_TEMPLATE_CSV_FROM_FIREBASE,
} from "@schoolbase/core";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      rules: {
        required: (v) => !!v || "This field is required",
        email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      },
      rulesFile: [
        (v) => !!v || "File is required",
        (v) => (v && v.size > 0) || "File is required",
      ],
      token: store.getters.getToken,
      isLoading: false,
      myLoading: false,
      internal_lecturer_csv: INTERNAL_LECTURE_TEMPLATE_CSV_FROM_FIREBASE,
      external_lecturer_csv: EXTERNAL_LECTURE_TEMPLATE_CSV_FROM_FIREBASE,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      search: "",
      phone_numbers: [],
      phone_numbers1: "",
      phone_numbers0: "",

      valid: true,
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Email", value: "email" },
        { text: "Gender", value: "gender" },
        { text: "Phone Number", value: "phone_numbers" },
      ],
      listLect: [],
      genderList: GENDER,
      nameTitleList: TITLE,
      title: "",
      contract_type_list: CONTRACT_TYPE,
      contract: "",
      dialog: false,
      parsed: false,
      dialog1: false,
      name_en: "",
      file: "",
      filecsv: [],
      obj_lects: {},
      name_kh: "",
      name: "",
      gender: "",
      lecturers: [],
      email: "",
      lectId: "",
      skills: "",
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event;
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.lecturers = results.data;
          window.console.log(this.lecturers);
          this.parsed = true;
          Object.assign(
            this.obj_lects,
            { contract: this.contract },
            { lecturers: this.lecturers }
          );
          window.console.log(this.obj_lects);
        }.bind(this),
      });
    },
    async importLectCSV() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await importLecturer(this.obj_lects);
        this.snackbar = {
          message: response.message,
          color: "success",
          show: true,
        };
        this.loadLecturer();
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
      this.isLoading = false;
      this.myLoading = false;
      this.resetValidation();
      this.clear();
      this.dialog1 = false;
    },
    onEditItem(item) {
      this.email = item.email;
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.lectId = item.id;
      this.gender = item.gender;
      this.title = item.title;
      this.contract = item.contract;
      this.phone_numbers0 = item.phone_numbers[0];
      this.phone_numbers1 = item.phone_numbers[1];
      this.dialog = true;
    },
    getGenderColor(gender) {
      if (gender === "Male") {
        return "primary";
      } else {
        return "pink";
      }
    },
    getContractColor(contract) {
      if (contract === "Full-Time") {
        return "green";
      } else {
        return "indigo";
      }
    },

    //load User Function
    async loadLecturer() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await getLecturer(this.token);
        this.listLect = response.payload.sort(function (a, b) {
          var nameA = a.name_en;
          var nameB = b.name_en;

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },

    //Create User Function
    async onSaveClose() {
      const data = {
        email: this.email,
        name_kh: this.name_kh,
        name_en: this.name_en,
        gender: this.gender,
        title: this.title,
        contract: this.contract,
        phone_numbers: [this.phone_numbers0, this.phone_numbers1],
      };
      if (this.lectId !== "") {
        try {
          await updateLecturer(this.lectId, data);
          this.loadLecturer();
          this.snackbar = {
            message: "Successfully Update Lecturer",
            color: "success",
            show: true,
          };
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      } else {
        try {
          await createLecturer(data);
          this.loadLecturer();
          this.snackbar = {
            message: "Successfully Create User",
            color: "success",
            show: true,
          };
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.resetValidation();
      this.clear();
      this.dialog = false;
    },
    clear() {
      this.email = "";
      this.name_kh = "";
      this.name_en = "";
      this.phone_numbers = "";
      this.phone_numbers1 = "";
      this.phone_numbers0 = "";
      this.gender = "";
      this.title = "";
      this.filecsv = null;
      this.contract = "";
      this.lectId = "";
      this.id = "";
    },
    async deletelect(lect) {
      const index = this.listLect.indexOf(lect);
      this.deletItems = lect;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listLect.splice(index, 1);
        await this.deleteLect();
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async deleteLect() {
      try {
        await deleteLecturer(this.deletItems.id);
        this.snackbar = {
          message: "Delete Lecturer Successfully",
          color: "success",
          show: true,
        };
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },
  async mounted() {
    this.loadLecturer();
  },
  components: {
    Loading,
  },
};
</script>

<style scoped>
.btn_create_new {
  color: #3316f2 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}
.v-data-table thead th {
  font-size: 16px !important;
  font-weight: 600;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}
.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}
.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

@media (max-width: 576px) {
}
</style>
